import React, { useState, useEffect } from "react";

const HeroCarousel = () => {
  const slides = ["/images/hero1.jpeg", "/images/hero1.jpeg"];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="relative w-full h-100">
      {/* Image from the public folder */}
      <img
        src={slides[currentSlide]}
        className="w-full h-100 object-cover"
        alt={`Slide ${currentSlide + 1}`}
      />
      <div className="absolute inset-0 flex items-center justify-center text-white text-center bg-black bg-opacity-50">
        <div>
          <h2 className="text-3xl font-bold">
            FLORIDA LOWEST PRICES - REAL ESTATE SCHOOL
          </h2>
          <p className="mt-2">
            Constantly challenging ourselves to deliver more to our students.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroCarousel;
