import React, { useState, useEffect } from "react";
import Testimonials from "./Testimonials";
import CourseSelection from "./CourseSelection";
import HeroCarousel from "./HeroCarousel";
import DocumentMeta from "react-document-meta";
import AboutSection from "./AboutSection";
import CoursesContent from "./CoursesContent";

const Courses = () => {
  const meta = {
    title: "Courses | Jm School of Real Estate",
    description:
      "Learn about Courses offered by Jm School of Real Estate, our mission, and team.",
    meta: {
      charset: "utf-8",
      name: {
        viewport: "width=device-width, initial-scale=1, shrink-to-fit=no",
      },
    },
  };

  return (
    <>
      <DocumentMeta {...meta}>
        <section className="container mx-auto p-8 text-center">
          <h2 className="text-4xl font-semibold text-blue-700">Course</h2>
          <p className="mt-4 text-lg text-gray-700">
            The Education You Need For The Career That You Want!
          </p>
          <CoursesContent />
        </section>
      </DocumentMeta>
    </>
  );
};
export default Courses;
