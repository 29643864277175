import React, { useState, useEffect } from "react";

const Testimonials = () => {
  const reviews = [
    {
      name: "Alex L.",
      text: "I cannot begin to explain how grateful I am to have been recommended to Gold Coast.",
    },
    {
      name: "Maria S.",
      text: "Gold Coast Schools made my real estate licensing journey so easy! The instructors are great.",
    },
    {
      name: "James T.",
      text: "Highly recommended for mortgage licensing. The online format was flexible and convenient.",
    },
  ];

  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const prevReview = () => {
    setCurrentReview((prev) => (prev - 1 + reviews.length) % reviews.length);
  };

  const nextReview = () => {
    setCurrentReview((prev) => (prev + 1) % reviews.length);
  };

  return (
    <section className="bg-blue-700 text-white text-center px-8 py-16 mt-12 relative">
      <h2 className="text-2xl font-semibold">What Our Students Say</h2>
      <div className="mt-6 relative">
        {/* Review Text */}
        <blockquote className="italic text-lg transition-opacity duration-500 ease-in-out">
          " {reviews[currentReview].text} "
        </blockquote>
        <p className="mt-2 font-bold">- {reviews[currentReview].name}</p>

        {/* Left Arrow */}
        <button
          onClick={prevReview}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 text-gray-800 p-2 rounded-full hover:bg-opacity-75"
        >
          &#10094;
        </button>

        {/* Right Arrow */}
        <button
          onClick={nextReview}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 text-gray-800 p-2 rounded-full hover:bg-opacity-75"
        >
          &#10095;
        </button>
      </div>

      {/* Indicator Dots */}
      <div className="mt-6 flex justify-center space-x-2">
        {reviews.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentReview(index)}
            className={`w-3 h-3 rounded-full ${
              currentReview === index ? "bg-white" : "bg-gray-400"
            }`}
          ></button>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
