import React, { useState, useEffect } from "react";
const CoursesContent = () => {
  return (
    <section className="container mx-auto mt-8 text-center">
      <h2 className="text-3xl font-semibold">
        The Education You Need For The Career That You Want!
      </h2>
      <div className="grid grid-cols-3 gap-6 mt-6">
        {[
          {
            title: "Real Estate",
            color: "text-blue-600",
            description: "Start or renew your license.",
          },
          {
            title: "Insurance",
            color: "text-yellow-600",
            description: "Life, Health, or General Lines.",
          },
          {
            title: "Construction",
            color: "text-orange-600",
            description: "Fulfill licensing requirements.",
          },
        ].map((course, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow">
            <h3 className={`${course.color} text-xl font-bold`}>
              {course.title}
            </h3>
            <p className="mt-2">{course.description}</p>
            <button className="bg-blue-600 text-white px-4 py-2 mt-4 rounded">
              View Classes
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};
export default CoursesContent;
