import React from "react";

const AboutSection = () => {
  return (
    <section className="py-12">
      <div className="max-w-[991px] mx-auto px-6">
        {/* Top Main Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 mb-12">
          <div className="flex flex-col md:flex-row items-start gap-6">
            {/* Placeholder Image */}
            <div className="w-full md:w-1/5">
              <div className="bg-gray-300 w-full h-24 md:h-32 rounded-lg">
                <img
                  className="h-24 md:h-32 rounded-lg"
                  src="/images/hero1.jpeg"
                />
              </div>
            </div>
            {/* Text Content */}
            <div className="w-full md:w-4/5">
              <h2 className="text-xl font-bold text-gray-800 mb-2">
                REAL ESTATE SCHOOL – ONLINE THINKING
              </h2>
              <p className="text-gray-600">
                JM School of Real Estate, Inc.{" "}
                <a
                  href="#"
                  className="text-blue-600 font-medium hover:underline"
                >
                  Lic # ZH1002197
                </a>{" "}
                was founded in 2006 by Jorge J. Miguel with the vision to
                provide a stimulating and motivational learning environment to
                licensee prospects and current licensees with personalized
                attention.
              </p>
              <p className="text-gray-600 mt-2">
                <strong>JORGE J MIGUEL</strong> – CEO AND FOUNDER
              </p>
              <p className="text-gray-600">
                FLORIDA BROKER – INSTRUCTOR SINCE 2004{" "}
                <a
                  href="#"
                  className="text-blue-600 font-medium hover:underline"
                >
                  LIC #ZH1002272
                </a>
              </p>
              <a
                href="#"
                className="inline-block mt-4 text-blue-600 font-semibold hover:underline"
              >
                Study Online →
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Two Cards */}
        <div className="grid md:grid-cols-2 gap-6 ">
          {/* Card 1 */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-lg font-bold text-gray-800 mb-2">
              WE HAVE TAUGHT THOUSANDS OF STUDENTS OF ALL NATIONALITIES
            </h3>
            <p className="text-gray-600">
              Besides our online courses, we offer in-person classes with no
              more than 20 students. We provide our students with the best
              possible education and attention each one of them deserves.
            </p>
            <p className="text-gray-600 mt-2">
              Our classes create an enjoyable ambiance by relating real-life
              scenarios to theoretical concepts.
            </p>
            <a
              href="#"
              className="inline-block mt-4 text-blue-600 font-semibold hover:underline"
            >
              Study Now →
            </a>
          </div>

          {/* Card 2 */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-lg font-bold text-gray-800 mb-2">
              OUR COMMITMENT TO HELP STUDENTS BUILD A SUCCESSFUL CAREER NETWORK
            </h3>
            <p className="text-gray-600">
              JM School of Real Estate can travel worldwide to provide classes
              for a minimum number of students who want to expand their market.
            </p>
            <p className="text-gray-600 mt-2">
              The Florida real estate market is booming. Why settle for a
              limited market and referral fees when you can get the full
              commission?
            </p>
            <a
              href="#"
              className="inline-block mt-4 text-blue-600 font-semibold hover:underline"
            >
              Where We Work →
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
