import React, { useState, useEffect } from "react";
import Testimonials from "./Testimonials";
import CourseSelection from "./CourseSelection";
import Courses from "./Courses";
import HeroCarousel from "./HeroCarousel";
import DocumentMeta from "react-document-meta";

const Homepage = () => {
  const meta = {
    title: "Jm School of Real Estate",
    description: "Learn about Jm School of Real Estate, our mission, and team.",
    meta: {
      charset: "utf-8",
      name: {
        viewport: "width=device-width, initial-scale=1, shrink-to-fit=no",
      },
    },
  };

  return (
    <>
      <DocumentMeta {...meta}></DocumentMeta>
    </>
  );
};
export default Homepage;
