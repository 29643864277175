import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-blue-700 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <h1 className="text-2xl font-medium">
          <a href="/">Jm School of Real Estate</a>
        </h1>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-white focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <span className="text-2xl">&times;</span> // Close Icon
          ) : (
            <span className="text-2xl">&#9776;</span> // Hamburger Icon
          )}
        </button>

        {/* Navbar Links */}
        <ul
          className={`md:flex space-x-6 md:space-x-6 absolute md:static bg-white md:bg-blue-700 text-gray-700 md:text-white md:bg-transparent top-16 left-0 w-full md:w-auto md:flex-row flex-col items-center transition-all duration-300 ease-in-out ${
            isOpen ? "flex z-10" : "hidden"
          }`}
        >
          <li className="py-2 md:py-0">
            <a href="/courses" className="hover:underline block px-4">
              Courses
            </a>
          </li>
          <li className="py-2 md:py-0">
            <a href="/about" className="hover:underline block px-4">
              Who we are
            </a>
          </li>
          <li className="py-2 md:py-0">
            <a href="/faq" className="hover:underline block px-4">
              FAQ
            </a>
          </li>
          <li className="py-2 md:py-0">
            <a href="/contact">
              <button className="bg-yellow-500 px-4 py-2 rounded block w-full md:w-auto">
                Contact
              </button>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
