import React from "react";

const FaqInfo = () => {
  return (
    <section className="max-w-3xl mx-auto p-8 text-center">
      {/* Title */}
      <h2 className="text-3xl font-bold text-gray-900">
        What Else? We Have The Best Support Around
      </h2>

      {/* Highlighted Text */}
      <div className="mt-4 bg-blue-100 p-4 rounded-lg text-gray-800 text-lg">
        <span className="font-semibold">JM Realty School</span> is{" "}
        <span className="font-bold text-blue-700">
          loaded with useful, functional course options
        </span>{" "}
        that allow users to quickly and easily learn the real estate career. But
        that’s not all, when you purchase your courses from us you will also get
        the{" "}
        <span className="font-bold text-blue-700">
          most amazing customer support around!
        </span>
      </div>

      {/* Features List */}
      <ul className="mt-6 text-left max-w-2xl mx-auto space-y-3">
        <li className="flex items-center space-x-2">
          <span className="text-gray-700">
            ☑ Online courses so your time will be most rewarding
          </span>
        </li>
        <li className="flex items-center space-x-2">
          <span className="text-gray-700">
            ☑ Easy to use, allowing you to quickly study your topic.
          </span>
        </li>
        <li className="flex items-center space-x-2">
          <span className="text-gray-700">
            ☑ Advanced courses with options panel to easily study your class
            needs
          </span>
        </li>
        <li className="flex items-center space-x-2">
          <span className="text-gray-700">
            ☑ Continuing education gives you the opportunity to be more
            knowledgeable
          </span>
        </li>
      </ul>

      {/* CTA Button */}
      <button className="mt-6 bg-green-600 text-white px-6 py-3 rounded-lg font-bold hover:bg-green-700 transition">
        GET YOUR COURSE NOW!
      </button>

      {/* Additional Services */}
      <div className="mt-12 text-left">
        {/* Florida Fingerprint Service */}
        <div className="mb-8">
          <h3 className="text-xl font-bold text-gray-900">
            Florida Fingerprint Service
          </h3>
          <p className="text-gray-700">
            Schedule and process your background check and fingerprints.
          </p>
          <a href="https://pearson.ibtfingerprint.com/" className="block mt-3">
            <img
              src="/images/img1.png"
              alt="Fingerprint Service"
              className="w-48 mx-auto hover:opacity-80 transition"
            />
          </a>
        </div>

        {/* Florida State Exam */}
        <div className="mb-8">
          <h3 className="text-xl font-bold text-gray-900">
            Florida State Exam
          </h3>
          <p className="text-gray-700">
            If you wish to register for an exam licensed by the State of
            Florida, click below.
          </p>
          <a
            href="https://www.catglobal.com/CATGlobal8/default.asp"
            className="block mt-3"
          >
            <img
              src="/images/img2.jpg"
              alt="State Exam"
              className="w-48 mx-auto hover:opacity-80 transition"
            />
          </a>
        </div>

        {/* Application Center */}
        <div className="mb-8">
          <h3 className="text-xl font-bold text-gray-900">
            Application Center
          </h3>
          <p className="text-gray-700">
            If you wish to register, check your status or find information on
            requirements, click below.
          </p>
          <a
            href="https://www.myfloridalicense.com/intentions2.asp?chBoard=true&boardid=25&SID"
            className="block mt-3"
          >
            <img
              src="/images/img3.png"
              alt="Application Center"
              className="w-48 mx-auto hover:opacity-80 transition"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default FaqInfo;
