import React from "react";

const CourseSelection = () => {
  return (
    <section className="bg-white shadow-lg p-6 max-w-4xl mx-auto mt-6 rounded-lg">
      {/* Responsive Grid Layout */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {/* Profession Dropdown */}
        <select className="border p-3 rounded w-full">
          <option>Select Your Profession</option>
          <option>Real Estate</option>
          <option>Insurance</option>
          <option>Construction</option>
        </select>

        {/* Path Dropdown */}
        <select className="border p-3 rounded w-full">
          <option>Select Your Path</option>
          <option>Get My License</option>
          <option>Renew My License</option>
        </select>

        {/* Course Dropdown */}
        <select className="border p-3 rounded w-full">
          <option>Select Your Course</option>
          <option>Sales Associate Pre-License</option>
          <option>Broker Course</option>
        </select>
      </div>

      {/* Button */}
      <div className="w-full flex justify-center mt-4">
        <button className="bg-blue-600 text-white px-6 py-3 mt-4 max-w-[300px] w-full rounded hover:bg-blue-700 transition">
          GO
        </button>
      </div>
    </section>
  );
};

export default CourseSelection;
