import React, { useState, useEffect } from "react";
import "./index.css"; // Tailwind styles
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Testimonials from "./components/Testimonials";
import CourseSelection from "./components/CourseSelection";
import CoursesContent from "./components/CoursesContent";
import Courses from "./components/Courses";
import HeroCarousel from "./components/HeroCarousel";
import About from "./components/About";
import Faq from "./components/Faq";
import Contact from "./components/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Homepage />
              <HeroCarousel />
              <CourseSelection />
              <CoursesContent />
              <Testimonials />
            </>
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/courses" element={<Courses />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
