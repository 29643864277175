import React, { useState, useEffect } from "react";
import DocumentMeta from "react-document-meta";
import FaqInfo from "./FaqInfo";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const meta = {
    title: "Frequently Asked Questions | Gold Coast Schools",
    description: "Frequently Asked Questions",
    meta: {
      charset: "utf-8",
      name: {
        viewport: "width=device-width, initial-scale=1, shrink-to-fit=no",
      },
      name: {
        description: "Frequently Asked Questions",
      },
    },
  };
  const faqData = [
    {
      question: "What is a broker?",
      answer:
        "A broker (BK) is a person who, for another, and for compensation or in anticipation of compensation, appraises, auctions, sells, exchanges, buys, rents or offers, attempts or agrees to appraise, auction, or negotiate any sale, exchange, purchase, or rental of business enterprises or any real property or any interest in or concerning the same or who advertises the same.",
    },
    {
      question: "What is a real estate instructor?",
      answer:
        "A real estate instructor (ZH) is an individual who instructs persons in the classroom in noncredit college courses in a college, university, or community college or courses in an area technical center or proprietary real estate school. The department issues a license/permit number to an instructor to teach Florida real estate courses.",
    },
    {
      question:
        "Where can I get the applications and forms if I want to mail my application?",
      answer:
        "These forms are available at www.myfloridalicense.com. Click on “Apply for a License”, then select “Real Estate” and the type of license you want to apply for. The application can be printed using the “Apply Using a Printable Application” button at the bottom of the page.",
    },
    {
      question: "Once I pass the state exam when will I get my license?",
      answer:
        "The testing vendor will download the passing grade information to the department’s system, and the system will automatically issue a license number. The department issues the license automatically within approximately 7-10 business days of passing the examination.",
    },
    {
      question: "Can I check the status of my application online?",
      answer:
        "Yes, to check the status of your application go to www.myfloridalicense.com, click on “Apply for License” from the left side under “Public Services”, and then click “View Application Status.”",
    },
    {
      question:
        "If a broker associate or sales associate works for a broker, can they work part-time for a rental or leasing company?",
      answer:
        "Yes, but only if the compensation is in the form of a salary, not commission. They may not receive bonuses, commissions, or gratuities (dinner, flowers, wine, tickets, whatever) of any kind, no matter what it is called.",
    },
    {
      question:
        "What is required from a licensee when they are moving out of state?",
      answer:
        "A licensee must update their current addresses with the department within 10 days and submit an “Irrevocable Consent to Service” form located on the Division of Real Estate forms page.",
    },
  ];
  return (
    <>
      <DocumentMeta {...meta}>
        <section className="container mx-auto p-8 text-center">
          <h1 className="text-4xl font-semibold text-blue-700">
            Frequently Asked Questions ?
          </h1>
          <p className="mt-4 text-lg text-gray-700">
            We are committed to providing top-quality education and training for
            real estate professionals.
          </p>

          <div className="max-w-3xl mx-auto mt-8 mb-8">
            {faqData.map((item, index) => (
              <div key={index} className="mb-4  border-gray-300">
                <button
                  className="w-full text-left flex justify-between items-center p-4 bg-gray-100 hover:bg-gray-200 rounded-lg focus:outline-none"
                  onClick={() => toggleFAQ(index)}
                >
                  <span className="text-lg font-medium">{item.question}</span>
                  <span className="text-xl">
                    {openIndex === index ? "−" : "+"}
                  </span>
                </button>
                {openIndex === index && (
                  <div className="p-4 text-gray-700 transition-all duration-300 text-left">
                    {item.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
          <FaqInfo />
        </section>
      </DocumentMeta>
    </>
  );
};
export default Faq;
