import React, { useState, useEffect } from "react";
import Testimonials from "./Testimonials";
import CourseSelection from "./CourseSelection";
import Courses from "./Courses";
import HeroCarousel from "./HeroCarousel";
import DocumentMeta from "react-document-meta";
import AboutSection from "./AboutSection";

const About = () => {
  const meta = {
    title: "About Us | Jm School of Real Estate",
    description: "Learn about Jm School of Real Estate, our mission, and team.",
    meta: {
      charset: "utf-8",
      name: {
        viewport: "width=device-width, initial-scale=1, shrink-to-fit=no",
      },
    },
  };

  return (
    <>
      <DocumentMeta {...meta}>
        <section className="container mx-auto p-8 text-center">
          <h2 className="text-4xl font-semibold text-blue-700">Who we are</h2>
          <p className="mt-4 text-lg text-gray-700">
            Challenging ourselves to bring the easier way to learn real estate
            online
          </p>
          <AboutSection />
          {/* Tile Layout */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
            {/* Tile 1 */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <img
                src="/images/mission.jpg"
                alt="Mission"
                className="w-full h-40 object-cover rounded-lg"
              />
              <h3 className="text-xl font-bold text-blue-600 mt-4">
                Our Mission
              </h3>
              <p className="text-gray-600 mt-2">
                To provide industry-leading education for aspiring
                professionals, ensuring career success.
              </p>
            </div>

            {/* Tile 2 */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <img
                src="/images/vision.jpg"
                alt="Vision"
                className="w-full h-40 object-cover rounded-lg"
              />
              <h3 className="text-xl font-bold text-blue-600 mt-4">
                Our Vision
              </h3>
              <p className="text-gray-600 mt-2">
                Empowering individuals with knowledge and skills to excel in
                their professions.
              </p>
            </div>

            {/* Tile 3 */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <img
                src="/images/team.jpg"
                alt="Team"
                className="w-full h-40 object-cover rounded-lg"
              />
              <h3 className="text-xl font-bold text-blue-600 mt-4">Our Team</h3>
              <p className="text-gray-600 mt-2">
                A passionate team of educators and professionals dedicated to
                your success.
              </p>
            </div>
          </div>
        </section>
      </DocumentMeta>
    </>
  );
};
export default About;
