import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white p-6 text-center">
      <p className="text-lg font-semibold">BROKER LICENSE: ZH 1002272</p>
      <p className="text-lg">P 786.287.4843</p>
      <p className="text-lg">
        <a
          href="https://www.jmrealtyschool.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-300 hover:underline"
        >
          www.JMrealtyschool.com
        </a>
      </p>

      <div className="mt-4">
        <a href="#" className="text-blue-300 hover:underline mx-2">
          About Us
        </a>
        <a href="#" className="text-blue-300 hover:underline mx-2">
          Privacy Policy
        </a>
        <a href="#" className="text-blue-300 hover:underline mx-2">
          Terms of Service
        </a>
      </div>

      {/* Copyright Section */}
      <div className="mt-6 border-t border-gray-700 pt-4 text-sm">
        <p>
          Copyright 2025 | JM School of Real Estate, Inc. | All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
